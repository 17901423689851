<template>
  <div>
    <h1 class="pb-2">
      {{ $t('Flow Chart') }}
    </h1>
    <!--        <vue-mermaid :nodes="states" type="graph TD" @nodeClick="editNode"></vue-mermaid>-->

    <p></p>
  </div>
</template>

<script>
/* import WorkflowChart from 'vue-workflow-chart'; */
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  data: () => ({
    states: [
      {
        id: '1',
        text: 'Lorem ipsum dolor.',
        link: '---',
        next: ['2'],
        editable: true,
        edgeType: 'round',
        style: 'fill:#fff,stroke:#0095e8,stroke-width:2px',
        linkStyle: 'fill:none,stroke:#0095e8,stroke-width:2px;',
      },
      {
        id: '2',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
        edgeType: 'round',
        next: ['3'],
        style: 'fill:#fff,stroke:#0095e8,stroke-width:2px',
        linkStyle: 'fill:none,stroke:#0095e8,stroke-width:2px;',
      },
      {
        id: '3',
        text: 'Lorem ipsum dolor sit amet.',
        edgeType: 'round',
        next: ['4', '6'],
        style: 'fill:#fff,stroke:#0095e8,stroke-width:2px',
        linkStyle: 'fill:none,stroke:#0095e8,stroke-width:2px;',
      },
      {
        id: '4',
        text: 'Lorem ipsum dolor sit.',
        edgeType: 'round',
        link: '-- This is the text ---',
        next: ['5'],
        style: 'fill:#fff,stroke:#0095e8,stroke-width:2px',
        linkStyle: 'fill:none,stroke:#0095e8,stroke-width:2px;',
      },
      {
        id: '5',
        text: 'Lorem ipsum dolor sit amet, consectetur.',
        edgeType: 'round',
        style: 'fill:#fff,stroke:#0095e8,stroke-width:2px',
        linkStyle: 'fill:none,stroke:#0095e8,stroke-width:2px;',
      },
      {
        id: '6',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
        edgeType: 'round',
        style: 'fill:#fff,stroke:#0095e8,stroke-width:2px',
      },
      // {
      //     id: "static_state_deleted",
      //     label: "Deleted",
      // }
    ],
    transitions: [
      {
        id: 'transition_1',
        target: 'state_2',
        source: 'state_1',
      },
      {
        id: 'transition_2',
        target: 'state_3',
        source: 'state_1',
      },
      {
        id: 'transition_3',
        target: 'state_4',
        source: 'state_1',
      },
      {
        id: 'transition_4',
        target: 'state_5',
        source: 'state_4',
      },
      {
        id: 'transition_4',
        target: 'state_6',
        source: 'state_4',
      },
      // {
      //     id: 'delete',
      //     target: 'static_state_deleted',
      //     source: 'state_4',
      // }
    ],
    stateSemantics: [
      {
        classname: 'delete',
        id: 'static_state_deleted',
      },
    ],
  }),
  methods: {
    onStateClick(id) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: `Clicked on state with id: ${id}`,
          variant: 'success',
        },
      })
    },
    editNode(nodeId) {
      alert(nodeId)
    },
  },
}
</script>

<style lang="scss">
@import '~vue-workflow-chart/dist/vue-workflow-chart.css';

.vue-workflow-chart-state-delete {
  color: white;
  background: #ff6767;
}

.vue-workflow-chart-transition-arrow-delete {
  fill: #ff6767;
}

.vue-workflow-chart-transition-path-delete {
  stroke: #ff6767;
}

.vue-workflow-chart-state {
  border-radius: 8px;
  padding: 8px 8px;
  min-width: 120px;
  font-size: 10px;
  font-family: 'Montserrat', Helvetica, Arial, serif;
}

.vue-workflow-chart-transition-path {
  stroke: #2478b9;
  stroke-width: 3;
}

.vue-workflow-chart-transition-arrow {
  fill: #2478b9;
}

foreignObject > div {
  background: #fff;

  .edgeLabel {
    color: #fff !important;
    background-color: #2478b9 !important;
    border-radius: 4px;
  }
}
</style>
